import styled from "styled-components"
import { Cta } from "../../SharedStyledComponents"

export const MainDiv = styled.div`
  padding: 0 15%;
  display: flex;

  margin-bottom: 2rem;
  flex: 1 0 auto;
  align-item:center;
  justify-content: center;
  @media (max-width: 768px) {

    padding: 0 1rem;
  }
`

export const ContactDetails = styled.div`
  width: 50%;
  padding-top: 3rem;
  padding-right: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem 0;
  }
`
export const Formular = styled.div`
  width: 50%;
  padding-top: 3rem;
  display:flex;
  flex-direction:column;
  align-items:center;

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem 0;
  }
`

export const Header = styled.h2`
  font-weight: bold;
  text-align:center;
`

export const Item = styled.a`
  color: black;
  display: flex;
  align-items:center;

  svg {
    height: 2rem;
    width: 2rem;
    margin-right: 0.5rem;
  }
`

export const FormGroup = styled.div`
  max-width: 500px;
`

export const Message = styled.p`
  text-align: center;
`
export const Icon = styled.div`
  width: 2rem;
  margin-right: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;

`

export const Input = styled.input`
  box-sizing:border-box;
  margin: 0.25rem 0;
  width: 100%;
  padding: 0.125rem 0.5rem;
  height: 2rem;
`

export const TextArea = styled.textarea`
 box-sizing:border-box;
  margin: 0.25rem 0;
  width: 100%;
  padding: 0.125rem 0.5rem;

`

export  const Button = styled(Cta)`
 box-sizing:border-box;
  width: 100%;
  padding: 0.5rem 0.5rem;
  margin: 0;
`
export const SubHeader = styled.p`


`