import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { stringify } from "qs"
import { serialize } from "dom-form-serializer"

import * as S from "./styledComponents"

class ContactFormPage extends React.Component {
  static defaultProps = {
    name: "ContactMe",
    subject: "ContactMe Website", // optional subject of the notification email
    action: "",
    successMessage: "Vielen Dank für Ihr Interesse. Wir werden uns so schnell wie möglich bei Ihnen melden.",
    errorMessage:
      "Ein Fehler ist aufgetreten. Versuchen Sie es erneut oder senden Sie uns eine E-Mail.",
  }

  state = {
    alert: "",
    disabled: false,
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled)
      return

    const form = e.target
    const data = serialize(form)
    this.setState({ disabled: true })
    fetch(form.action + "?" + stringify(data), {
      method: "POST",
    })
      .then(res => {
        if (res.ok) {
          return res
        } else {
          throw new Error("Network error")
        }
      })
      .then(() => {
        form.reset()
        this.setState({
          alert: this.props.successMessage,
          disabled: false,
        })
      })
      .catch(err => {
        console.error(err)
        this.setState({
          disabled: false,
          alert: this.props.errorMessage,
        })
      })
  }

  render() {
    const { name, subject, action } = this.props
    return (
      <S.MainDiv>
        <S.Formular>
          <S.Header>{this.props.localizedText.WeHelp}</S.Header>
          <S.SubHeader>{this.props.localizedText.WeContactFast}</S.SubHeader>
          <S.FormGroup>
            <form
              name={name}
              action={action}
              data-netlify="true"
              onSubmit={this.handleSubmit}
            >
              <input type="hidden" name="form-name" value={name} />
              <S.Input
                type="text"
                name="FirstName"
                placeholder="Name*"
                required
              />{" "}
              <br />

              <S.Input type="email" name="Email" placeholder="E-Mail" />
              <S.Input type="phone" name="phone" placeholder="Telefon" />
              <br />
  
              <S.TextArea
                id="message"
                name="Message"
                rows="4"
                cols="50"
                placeholder={this.props.localizedText.placeholder}
              />{" "}
              <br />
              
              {!!subject && (
                <input type="hidden" name="subject" value={subject} />
              )}
              {this.state.alert && <S.Message>{this.state.alert}</S.Message>}
              <S.Button type="submit" disabled={this.state.disabled}>
                {this.props.localizedText.BtnSend}
              </S.Button>
            </form>
          </S.FormGroup>
        </S.Formular>
      </S.MainDiv>
    )
  }
}

export default ContactFormPage
