import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useLocation } from "@reach/router"
import ContactMe from "../../components/ContactForms/ContactForm"
import { frCH } from "../../lang/fr-CH"

export default function Home() {
  const location = useLocation()

  const country = "CH"
  const lang = "fr-CH"
  const txt = frCH.ContactMe

  return (
    <Layout location={country} path={location.pathname} language={lang}>
      <Seo
        title="DoryGo - Anfragen"
        description="Ich möchte beratet werden"
        lang={lang}
      />

      <ContactMe localizedText={txt} />

    </Layout>
  )
}
